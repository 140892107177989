<template>
  <div v-if="loading">loading...</div>
  <div v-else>
    <Title :pages="pages">
      <Button as="router-link" :to="viewRoute"> View </Button>
    </Title>

    <div class="py-3 px-4 sm:px-6 lg:px-8">
      <Form
        :type-id="type._id"
        :adapter-id="adapter._id"
        :values="values"
        @submit="handleUpdate"
      />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, inject, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Button from "@/components/common/Button";
import Title from "@/components/common/Title";
import Form from "@/components/connections/Form";

export default defineComponent({
  components: {
    Button,
    Form,
    Title,
  },
  setup() {
    const { params } = useRoute();
    const { push } = useRouter();
    const $axios = inject("axios");

    const loading = ref(true);
    const connection = ref(null);

    const type = ref(null);
    const adapter = ref(null);
    const adapterType = ref(null);

    const pages = computed(() => {
      return [
        {
          name: "Connections",
          href: "connections.index",
          current: false,
        },
        {
          name: connection.value ? connection.value.name : "View",
          href: "connections.view",
          current: false,
        },
        {
          name: "Edit",
          href: "connections.edit",
          current: true,
        },
      ];
    });

    const viewRoute = computed(() => {
      return {
        name: "connections.view",
        params: { connection: connection.value?._id },
      };
    });

    const values = computed(() => {
      return {
        ...connection.value.values,
        name: connection.value?.name,
      };
    });

    async function handleUpdate({ setSubmitted, setSubmitting, values }) {
      setSubmitting();

      try {
        await $axios.put(`/connections/${params.connection}`, values);
        push({
          name: "connections.view",
          params: { connection: params.connection },
        });
      } catch (error) {
        //
      }

      setSubmitted();
    }

    (async function () {
      loading.value = true;

      try {
        const { data: item } = await $axios.get(
          `/connections/${params.connection}`
        );

        const [
          { data: typeResponse },
          { data: adapterResponse },
          { data: adapterTypeResponse },
        ] = await Promise.all([
          $axios.get(`/types/${item.data.type._id}`),
          $axios.get(`/adapters/${item.data.adapter._id}`),
          $axios.get(
            `/adapters/${item.data.adapter._id}/types/${item.data.type._id}`
          ),
        ]);

        connection.value = item.data;
        type.value = typeResponse.data;
        adapter.value = adapterResponse.data;
        adapterType.value = adapterTypeResponse.data;
      } catch (error) {
        //
      }

      loading.value = false;
    })();

    return {
      pages,
      type,
      values,
      adapter,
      loading,
      viewRoute,
      connection,
      adapterType,
      handleUpdate,
    };
  },
});
</script>
